import { useRef, useState, useEffect, useLayoutEffect } from "react";
import "./YearInReviewAnimation.scss";
import Draggable from "react-draggable";
// import useElementInView from "./hooks/useElementInView";
//TODO: trash the useElementInView hook, we no longer need IntersectionObservers

// import { Parallax } from "react-scroll-parallax"; ...lol
//TODO: 'npm remove react-scroll-parallax'. we aren't really using a parallax scroll here, just a parallax *animation*
//this is because the parallax animation is linked to *another* element's scroll state

import { FlapDisplay, Presets } from "react-split-flap-effect";
import "./themes.css"; //this is just for react-split-flap-effect
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import Modal from 'react-modal';

Modal.setAppElement('#root-yearinreview');
const factor = 0.66;

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

const url = window.location.href;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

//innerWidth : breakpoint
const shutterBreakpoints = {
  344: -420,
  414: -370,
  430: -500, //430x 14 pro max
  599: -400, //fold phones
  600: -380,
  //600 to 905 range is mostly handled by tablet portrait mode breakouts
  1280: -564,
  2560: -600, //first lock
  // 2560: -660, //second lock
};

function returnWhenUnderBreakpoint(value, windowHeight) {
  if (value < windowHeight && value > 767) {
    //then we are in tablet portrait mode territory
    return value === 768
      ? -690
      : value < 821
      ? -720
      : value < 1025
      ? -800
      : value < 1240
      ? -750
      : value < 1440
      ? -750
      : value < 1640
      ? -1200
      : value < 1920
      ? -1300
      : -528;
  }
  for (const breakpoint in shutterBreakpoints) {
    if (value <= breakpoint) return shutterBreakpoints[breakpoint];
  }
  return -528; //safest value
}

function YearInReviewAnimation(props) {
  const {
    general,
    slide0,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
  } = props;

  const [width, height] = useWindowSize();

  const dragRef = useRef();

  const printRef = useRef();

  const [loaded, setLoaded] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [languageModal, setLanguageModal] = useState(false);

  const [openShutter, setOpenShutter] = useState(true);
  const [dynamicShutterTop, setDynamicShutterTop] = useState(-528);

  const [printing, setPrinting] = useState(false);

  const [bgImage, setBgImage] = useState(`${slide1.backgroundImage}`);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [generatedSlideImages, setGeneratedSlideImages] = useState([]);
  const [newSlideImage, setNewSlideImage] = useState(null);
  const [selectedSlideImages, setSelectedSlideImage] = useState([]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }


  const handleResize = () => {
    setViewportWidth(window.innerWidth);
  };

  // const tmpShowPrintingSection = true;

  useEffect(() => {

    if (generatedSlideImages) {
      console.log("generatedSlideImages --- from useEffect:", generatedSlideImages);
    }

    if(selectedSlideImages){
      console.log("setSelectedSlideImage -- ", selectedSlideImages);
    }
    // openModal();
    console.log("width", width);
    setDynamicShutterTop(returnWhenUnderBreakpoint(width, height));
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
    setViewportWidth(window.innerWidth);
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, generatedSlideImages, selectedSlideImages]);


  // const printing = false;

  // const screenshots = useCallback(() => {
  //   setPrinting(true);
  //   if (printRef.current === null) {
  //     return;
  //   }

  //   toPng(printRef.current, { cacheBust: true })
  //     .then((dataUrl) => {
  //       const link = document.createElement("a");
  //       link.download = "my-image-name.png";
  //       link.href = dataUrl;
  //       link.click();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [printRef]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const setNewSlideImageHelper = (newImg) =>{
    
    setNewSlideImage(newImg);
    console.log('new image is');
    console.log(newSlideImage);
  }
  const screenshotsTypeTwo = async () =>
    // htmlToImage
    //   .toJpeg(document.getElementById("SLIDE_0"), { quality: 0.95 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement("a");
    //     link.download = "cx-yr-in-review-0.jpeg";
    //     link.href = dataUrl;
    //     link.click();
    //   });

    {
      setGeneratedSlideImages([]);

      setPrinting(true);

      console.log("printing...");
      console.log("the width of the view port is..", viewportWidth);

      var shareAPI = false;

      let shareData = {
        files: [],
        title: "My Journey",
        text: "",
      };

      // navigator.share(data)
      if (navigator.canShare && viewportWidth < 601) {
        console.log(
          "the browser supports share and user is on a mobile view port"
        );
        shareAPI = true;
        console.log("can share now....");
      }

      // shareAPI = true; //manually change this for testing
      const rootElement = document.getElementById("root-yearinreview");
      if (rootElement) {
        // Add the desired class
        rootElement.classList.add("printing");
      }
      console.log("printing...after rootElement");
      const noOfSlides = 10;
      // await delay(2000);

      for (let ind = 1; ind < noOfSlides; ind++) {
        // const element = array[index];
        console.log("inside the loop");
        console.log(ind);
        // await delay(1000);
        console.log("indx is: ", ind);
        if (ind == 9) {
          console.log("indx is: ", ind);
          // return;
          break; //only exit the loop
        }

        var PRINT_JOURNEY = document.getElementById("PRINT_JOURNEY");

        // Set the background image
        // if(PRINT_JOURNEY){
        // PRINT_JOURNEY.style.backgroundImage =
        console.log("able to get the element PRINT_JOURNEY");
        if (ind == 1) {
          setBgImage(slide1.backgroundImage);
        }
        if (ind == 2) {
          setBgImage(slide2.backgroundImage);
        }
        if (ind == 3) {
          setBgImage(slide3.backgroundImage);
        }
        if (ind == 4) {
          setBgImage(slide4.backgroundImage);
        }
        if (ind == 5) {
          setBgImage(slide5.backgroundImage);
        }
        if (ind == 6) {
          setBgImage(slide6.backgroundImage);
        }
        if (ind == 7) {
          setBgImage(slide7.backgroundImage);
        }
        if (ind == 8) {
          setBgImage(slide8.backgroundImage);
        }
        // }
        if (shareAPI) {
          await delay(1000);
        } else {
          await delay(1000);
        }

        console.log('before..generatedSlideImages');
        console.log(generatedSlideImages);
        
        if (shareAPI) {
          htmlToImage
            // .toPng(document.getElementById("SLIDE_0_PRINT"))
            .toBlob(document.getElementById("PRINT_JOURNEY"))
            .then(function (dataUrl) {
              var file = new File([dataUrl], `cx-yearin-review-2024-${ind}.png`, {
                type: dataUrl.type,
              });

              console.log(file);
              
              shareData.files.push(file);
              // setNewSlideImage(`slide${ind}.png`);
              // setNewSlideImage(file);
              // setNewSlideImageHelper(`slide${ind}.png`);

              // console.log('the new slide image is...');
              // console.log(newSlideImage);
              // setNewSlideImage(null);

            });
          await delay(500);
          // console.log('the new slide image is...');
          // console.log(newSlideImage);
          // setGeneratedSlideImages([...generatedSlideImages, newSlideImage]);
          // setNewSlideImage(null);
          
          document
            .getElementById(`SLIDE_${ind}_PRINT`)
            .classList.remove("active");
          //  if(ind == 7){
          //   await delay(2000);
          //  }
          if (ind < 8) {
            document
              .getElementById(`SLIDE_${ind + 1}_PRINT`)
              .classList.add("active");
          } else {
            document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
          }
        } else {
          htmlToImage
            // .toPng(document.getElementById("SLIDE_0_PRINT"))
            .toPng(document.getElementById("PRINT_JOURNEY"))
            .then(function (dataUrl) {
              //print slide 0
              //  download(dataUrl, `slide${index}.png`);
              download(dataUrl, `cx-yearin-review-2024-${ind}.png`);
            });
          await delay(500);

          document
            .getElementById(`SLIDE_${ind}_PRINT`)
            .classList.remove("active");
          //  if(ind == 7){
          //   await delay(2000);
          //  }
          if (ind < 8) {
            document
              .getElementById(`SLIDE_${ind + 1}_PRINT`)
              .classList.add("active");
          } else {
            document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
          }
        }
      }

      console.log("files for sharing:");
      console.log(shareData);

      if(shareAPI){
        setGeneratedSlideImages(shareData.files);
        console.log('generated images');

        // await delay(3000);
        console.log(generatedSlideImages);
        openModal();
      }
      // if (shareAPI) {
      //   try {
      //     // const userConfirmed =  window.confirm("You Journey is ready!, Would you like to share?");
      //     // if (userConfirmed) {
      //     //     // User clicked "OK"
      //     //    await navigator.share(shareData);

      //     //     console.log("User confirmed the action.");
      //     // } else {
      //     //     // User clicked "Cancel"
      //     //     console.log("User cancelled the action.");
      //     // }

      //     // if (shareData.files.length > 7) {
      //     //   await navigator.share(shareData);
      //     // } else {
      //     //   console.log("couldnt generate all the 8 files");
      //     // }
          
      //     setGeneratedSlideImages(shareData.files);
      //     console.log('generated images');

      //     // await delay(3000);
      //     console.log(generatedSlideImages);
      //     openModal();
      //   } catch (error) {
      //     console.log("something went wrong when attempting to share..");
      //     console.log(error);
      //     //if share API fails then download the images
      //     shareData.files.map((item, index) =>
      //       // <li key={index}>{item}</li>
      //       download(item, `slide${index + 1}.png`)
      //     );
      //   }
      // }
      rootElement.classList.remove("printing");
      setPrinting(false);
      
    };

  const  handleCheckboxChange = (index, event) => {

    if(event.target.checked){
      console.log('item is checked');

      if(!selectedSlideImages.includes(index)){
        setSelectedSlideImage(selectedSlideImages => [...selectedSlideImages, index]);
      }

    }else{
      console.log('item is unchecked');
      if(selectedSlideImages.includes(index)){
        setSelectedSlideImage(selectedSlideImages => selectedSlideImages.filter(item => item !== index));
      }
    }
  }

  const shareYourJourneyButton = () => {

    let canShare = true;
    if(navigator.canShare){
      canShare = true;
    }

    if(canShare){
      let shareData = {
        files: [],
        title: "My Journey",
        text: "",
      };

      generatedSlideImages.forEach((img, indx) => {
        if(selectedSlideImages.includes(indx)){
          shareData.files.push(generatedSlideImages[indx]);
        }
      });

      
      navigator.share(shareData).then(() =>{
        console.log('share was successful!');
        closeModal();
      }).catch((error) => {
        console.log(error);
      });
    
      
    }
  }
  // }},[])
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_1"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-1.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_2"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-2.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_3"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-3.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_4"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-4.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_5"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-5.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_6"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-6.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_7"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-7.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // htmlToImage
  //   .toJpeg(document.getElementById("SLIDE_8"), { quality: 0.95 })
  //   .then(function (dataUrl) {
  //     var link = document.createElement("a");
  //     link.download = "cx-yr-in-review-8.jpeg";
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // };

  console.log(dynamicShutterTop);

  return (
    <>
      {languageModal && (
        <div className="mask" onClick={() => setLanguageModal(false)} />
      )}
      <div
        className="main-parallax-wrapper"
        style={{ display: printing ? "none" : "" }}
      >
        <div
          className="main-parallax"
          style={{
            top: `${(-35800 * factor) / 2 + scrollPosition * 0.4}px`, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
            background: `url(${general.mainParallaxImgUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div className="blur blur-l" />
        <div className="blur blur-r" />
        <div className="blur blur-t" />
        {/* <div className="blur blur-b" /> */}
      </div>

      {/* <div
        className={`shutter-draggable-wrapper `}
        style={{ display: printing ? "none" : "" }}
        onWheel={() => setOpenShutter(true)}
      >
        <Draggable
          nodeRef={dragRef}
          axis="y"
          bounds={{
            top: dynamicShutterTop,
            bottom: 0,
          }} //does not accept vh
          position={
            openShutter
              ? {
                  y: dynamicShutterTop,
                  x: 0,
                }
              : undefined
          } //does not accept vh
          onDrag={() => setOpenShutter(true)}
          onClick={() => setOpenShutter(true)}
          onScroll={() => setOpenShutter(true)}
          disabled={!loaded}
        >
          <div ref={dragRef} className="shutter-wrapper">
            {!loaded && (
              <div className="loading">
                <div className="loading-spinner-outer">
                  <div className="loading-spinner-inner" />
                </div>
              </div>
            )}
            <div
              className="shutter"
              style={{
                background: `url("https://cxyearinreview.rhmdev.info/public-assets/general/shutter.svg")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          </div>
        </Draggable>
      </div> */}

      <div
        id="ww"
        className={`wrapper-wrapper ${!openShutter ? "pteventsnone" : ""}`}
        onScroll={(e) => {
          const { scrollTop } = e.target;
          const position = scrollTop;
          // console.log(position);
          setScrollPosition(position);
        }}
        style={{ display: printing ? "none" : "" }}
      >
        <div
          id="journey"
          className="journey-parallax-wrapper"
          style={{ display: printing ? "none" : "" }}
        >
          <div className="journey-parallax">
            <div
              id="SLIDE_0"
              className={`slide slide_0 opened ${loaded ? "loaded" : ""} ${
                scrollPosition < 3950 * factor
                  ? "sticky"
                  : scrollPosition > 3555 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              {/* <span className="medium">{general.year}</span> */}
              <span className="large vvnarrow wrap">
                {slide0.landingTop}
                <br />
              </span>
              <span className="large vvnarrow wrap">{slide0.landingTitle}</span>
              <span className="small wrap">{slide0.landingScrollLabel}</span>
              <div className="arrowanim">
                <img src={slide0.arrowDownIcon} />
              </div>
            </div>
            <div
              id="SLIDE_1"
              className={`slide slide_1 ${
                scrollPosition > 4345 * factor && scrollPosition < 7500 * factor
                  ? "sticky"
                  : scrollPosition > 7500 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              <span className="medium">{slide1.memberYearDescription}</span>
              <div className="split-flap">
                <FlapDisplay
                  className="XL"
                  chars={Presets.NUM}
                  length={slide1.memberYearCount.length}
                  value={
                    scrollPosition > 4345 * factor
                      ? slide1.memberYearCount.toString()
                      : "0"
                  }
                  timing={30}
                  hinge
                />
                <span className="sf-medium pushup">
                  {slide1.memberYearUnit}
                  {slide1.memberYearUnit === "year" ? "s" : ""}
                </span>
              </div>
            </div>
            <div
              id="SLIDE_2"
              className={`slide slide_2 ${
                scrollPosition > 8295 * factor &&
                scrollPosition < 11850 * factor
                  ? "sticky"
                  : scrollPosition > 11455 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              <>
                <img className="flight-icon" src={slide2.flightIcon} />
                <span className="medium">{slide2.flightTopLine1}</span>
                <span className="medium">{slide2.flightTopLine2}</span>
                <div className="factoid col">
                  <span className="xlarge">{slide2.flightTimes}</span>
                  <span className="sf-medium">{slide2.flightUnit}</span>
                  {Number(slide3.miles) === 0 && (
                    <span className="sf-medium">{slide2.flightBottom}</span>
                  )}
                </div>
                <div className="runway-wrapper">
                  <img className="road" src={slide2.roadIcon} />
                  <img className="divider" src={slide2.roadDividerIcon} />
                </div>
              </>
            </div>
            <div
              id="SLIDE_3"
              className={`slide slide_3 ${
                scrollPosition > 12600 * factor &&
                scrollPosition < 15800 * factor
                  ? "sticky"
                  : scrollPosition > 15405 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              {Number(slide3.miles) === 0 && (
                <>
                  <img src={slide3.earthIcon} />
                  <span className="medium">{slide3.miles0Top}</span>
                  <div className="factoid">
                    <span className="large">{slide3.miles0Fact}</span>
                  </div>
                  <div>
                    <div className="or-wrapper">
                      <div className="fatglobe-wrapper">
                        <img src={slide3.earthIcon} />
                      </div>
                      <span className="medium">{slide3.milesOrLabel}</span>
                    </div>
                  </div>
                  <div
                    className="hongkong"
                    style={{
                      background: `url(${slide3.hongkongImage})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                  <div className="factoid overdots">
                    <span className="large">{slide3.miles0Bottom}</span>
                  </div>
                </>
              )}
              {Number(slide3.miles) > 0 && (
                <>
                  <span className="medium">{slide3.miles1PlusTop}</span>
                  <div className="factoid">
                    <span className="vlarge">{slide3.miles}</span>
                    <span className="medium">{slide3.miles1PlusUnit}</span>
                  </div>
                  <div>
                    <div className="or-wrapper">
                      <div className="fatglobe-wrapper">
                        <img src={slide3.earthIcon} />
                      </div>
                      <span className="medium">{slide3.milesOrLabel}</span>
                    </div>
                  </div>
                  <div className="factoid narrow">
                    <span className="large">
                      {slide3.miles1PlusGlobeCount} {slide3.miles1PlusBottom}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div
              id="SLIDE_4"
              className={`slide slide_4 ${
                scrollPosition > 16400 * factor &&
                scrollPosition < 19355 * factor
                  ? "sticky"
                  : scrollPosition > 19355 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              <img src={slide3.earthIcon} />
              <span className="large wrap pbup">
                {slide4.cloudTopLine1} {slide4.cloudTopLine2}
              </span>
              <div className="cloud-wrapper-wrapper">
                <div className="cloud-wrapper">
                  {/* <img src={slide4.cloudIcon} /> */}
                </div>
                <span className="xlarge">{slide4.cloudDays}</span>
              </div>
              <span className="medium pbup">{slide4.cloudUnit}</span>
              <span className="medium">{slide4.cloudBottom}</span>
            </div>
            <div
              id="SLIDE_5"
              className={`slide slide_5 ${
                scrollPosition > 20145 * factor &&
                scrollPosition < 23305 * factor
                  ? "sticky"
                  : scrollPosition > 23305 * factor
                  ? "scrolledpast"
                  : ""
              } `}
            >
              <img className="takeoff" src={slide5.landIcon} />
              <span className="medium setWidth wrap">{slide5.tripTop}</span>
              {slide5.cities.map((c) => (
                <div key={c} className="underline">
                  <span className="large">{c}</span>
                </div>
              ))}
            </div>
            <div
              id="SLIDE_6"
              className={`slide slide_6 ${
                scrollPosition > 24095 * factor &&
                scrollPosition < 27650 * factor
                  ? "sticky"
                  : scrollPosition > 27650 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              <span className="medium">{slide6.bankedMilesTopLine1}</span>
              <span className="medium">{slide6.bankedMilesTopLine2}</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={slide6.asiaMilesIcon} />
                <span className="xlarge">{slide6.asiaMilesEarned}</span>
              </div>
              <span className="medium">{slide6.bankedMilesBottom}</span>
            </div>
            <div
              id="SLIDE_7"
              className={`slide slide_7 ${
                scrollPosition > 28045 * factor &&
                scrollPosition < 31205 * factor
                  ? "sticky"
                  : scrollPosition > 31205 * factor
                  ? "scrolledpast"
                  : ""
              }`}
            >
              <span>{slide7.favWaysTop}</span>
              {slide7.ways.map((w) => (
                <div key={w} className="underline">
                  <span className="large">{w}</span>
                </div>
              ))}
              <span>{slide7.favWaysBottom}</span>
            </div>
            <div
              id="SLIDE_8"
              className={`slide slide_8
                  ${scrollPosition > 33000 * factor ? "sticky" : ""}`}
            >
              <div className="plane-wrapper">
                <img src={slide8.airplaneIcon} />
              </div>
              <span className="medium setWidth wrap">{slide8.endLabel}</span>
              <div className="factoid">
                <span className="xlarge">{slide8.endYear}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="cloud-parallax-wrapper"
        style={{ display: printing ? "none" : "" }}
      >
        <div
          className="clouds_1"
          style={{
            top: `calc(-2000px - ${scrollPosition * 1}px)`, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
            background: `url(${general.cloudParallaxOneImgUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
          }}
        />
        <div
          className="clouds_2"
          style={{
            top: `${-1100 + scrollPosition * 0.05}px`, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
            background: `url(${general.cloudParallaxTwoImgUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
          }}
        />
      </div>

      <div
        className="window-frame-wrapper"
        style={{ display: printing ? "none" : "" }}
      >
        <img className="cathay-logo" src={general.cathayLogo} />
        {general.membershipTier === "GR" && (
          <img className="grow" src={general.windowFrameGreenImgUrl} />
        )}
        {general.membershipTier === "SL" && (
          <img className="grow" src={general.windowFrameSilverImgUrl} />
        )}
        {general.membershipTier === "GO" && (
          <img className="grow" src={general.windowFrameGoldImgUrl} />
        )}
        {general.membershipTier === "DM" && (
          <img className="grow" src={general.windowFrameDiamondImgUrl} />
        )}
      </div>
      <div className="progress-bar-wrapper">
        {scrollPosition < 3950 * factor && (
          <div className="disclaimer">{slide0.disclaimer}</div>
        )}
        {scrollPosition < 33000 * factor ? (
          <div
            className={`desktop-only control lang ${
              languageModal ? "selected" : ""
            }`}
            onClick={() => setLanguageModal(!languageModal)}
          >
            {!languageModal && <img src={general.languageIcon} />}
            {languageModal && <img src={general.languageIconSelected} />}
          </div>
        ) : (
          <div
            className="share-journey-div"
            style={{}}
            // onClick={() => screenshotsTypeTwo()}
          >
            <button
              type="button"
              className="control"
              onClick={() => screenshotsTypeTwo()}
            >
              Share Your Journey
            </button>
          </div>
        )}
        {scrollPosition > 33000 * factor && (
          <div
            className="desktop-only control link"
            onClick={() => screenshotsTypeTwo()}
          >
            <img
              src={general.linkIcon}
              onClick={() => navigator.clipboard.writeText(url)}
            />
          </div>
        )}
      </div>
      {/* <div className="membership-wrapper">
        <div
          className={`membership ${
            scrollPosition < 2550 && scrollPosition > 900 ? "slideout" : ""
          }`}
        >
          {general.membershipTier === "GR" && (
            <img src={general.greenMemberImgUrl} />
          )}
          {general.membershipTier === "SL" && (
            <img src={general.silverMemberImgUrl} />
          )}
          {general.membershipTier === "GO" && (
            <img src={general.goldMemberImgUrl} />
          )}
          {general.membershipTier === "DM" && (
            <img src={general.diamondMemberImgUrl} />
          )}
          <span>
            {general.membershipTier === "DM"
              ? "Diamond Member"
              : general.membershipTier === "GO"
              ? "Gold Member"
              : general.membershipTier === "SL"
              ? "Silver Member"
              : "Green Member"}
          </span>
        </div>
      </div> */}

      {languageModal && (
        <div className="language-select-wrapper">
          {general.languageList.map((l) => (
            <span key={l.languageCode}>{l.languageLabel}</span>
          ))}
        </div>
      )}

      <div
        className="duringPrintingLoader"
        style={{ display: printing ? "block" : "none" }}
      >
        <div className="loading">
          <div className="loading-spinner-outer">
            <div className="loading-spinner-inner" />
          </div>
          <p>Preparing Your Journey's Export....</p>
        </div>
      </div>

      <div
        id="PRINT_JOURNEY"
        className={`print-journey ${printing ? "printing" : ""}`}
        style={{
          display: !printing ? "none" : "",
          //  background: `url(${general.cloudParallaxTwoImgUrl})`,
          backgroundImage: `url(${bgImage})`,
        }}
        // style={{top: `calc(100vh + 100px)`, zIndex:9999}}
      >
        <div className="window-frame-wrapper">
          <img className="logo" src={general.cathayLogo} />
          <img className="windowTop" src={slide0.backgroundImage} />
          {general.membershipTier === "GR" && (
            <img className="frame" src={general.windowFrameGreenImgUrl} />
          )}
          {general.membershipTier === "SL" && (
            <img className="frame" src={general.windowFrameSilverImgUrl} />
          )}
          {general.membershipTier === "GO" && (
            <img className="frame" src={general.windowFrameGoldImgUrl} />
          )}
          {general.membershipTier === "DM" && (
            <img className="frame" src={general.windowFrameDiamondImgUrl} />
          )}

          {/* <div
          id="SLIDE_0_PRINT"
          ref={printRef}
          className={`slide slide_0 loaded active`}
        >
          <span className="medium">{general.year}</span>
          <span className="medium vnarrow wrap">
            NEED CORS {slide0.landingTop}
            <br />
          </span>
          <span className="large vvnarrow wrap">{slide0.landingTitle}</span>
          <span className="small wrap">{slide0.landingScrollLabel}</span>
          <div className="arrowanim">
                <img src={slide0.arrowDownIcon}  />
              </div>
        </div> */}
          <div id="SLIDE_1_PRINT" className={`slide slide_1 active`}>
            <span className="large">{slide1.memberYearDescription}</span>
            <div className="split-flap">
              {/* <FlapDisplay
              className="XL"
              chars={Presets.NUM}
              length={slide1.memberYearCount.length}
              value={
                scrollPosition > 4345 * factor
                  ? slide1.memberYearCount.toString()
                  : "0"
              }
              timing={30}
              hinge
            /> */}
              <span className="xlarge">
                {slide1.memberYearCount.toString()}
              </span>
              <span className="sf-medium">
                {slide1.memberYearUnit}
                {slide1.memberYearUnit === "year" ? "s" : ""}
              </span>
            </div>
          </div>
          <div id="SLIDE_2_PRINT" className={`slide slide_2`}>
            <>
              <img src={slide2.flightIcon} />
              <span className="large">{slide2.flightTopLine1}</span>
              <span className="large">{slide2.flightTopLine2}</span>
              <div className="factoid col">
                <span className="xlarge">{slide2.flightTimes}</span>
                <span className="sf-medium">{slide2.flightUnit}</span>
                {Number(slide3.miles) === 0 && (
                  <span className="sf-medium">{slide2.flightBottom}</span>
                )}
              </div>
              <div className="runway-wrapper">
                <img className="road" src={slide2.roadIcon} />
                <img className="divider" src={slide2.roadDividerIcon} />
              </div>
            </>
          </div>
          <div id="SLIDE_3_PRINT" className={`slide slide_3`}>
            {Number(slide3.miles) === 0 && (
              <>
                <img src={slide3.earthIcon} />
                <span className="medium">{slide3.miles0Top}</span>
                <div className="factoid">
                  <span className="large">{slide3.miles0Fact}</span>
                </div>
                <div>
                  <div className="or-wrapper">
                    <div className="fatglobe-wrapper">
                      <img src={slide3.earthIcon} />
                    </div>
                    <span className="medium">{slide3.milesOrLabel}</span>
                  </div>
                </div>
                <div
                  className="hongkong"
                  style={{
                    background: `url(${slide3.hongkongImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <div className="factoid overdots">
                  <span className="large">{slide3.miles0Bottom}</span>
                </div>
              </>
            )}
            {Number(slide3.miles) > 0 && (
              <>
                <span className="medium">{slide3.miles1PlusTop}</span>
                <div className="factoid">
                  <span className="vlarge">{slide3.miles}</span>
                  <span className="medium">{slide3.miles1PlusUnit}</span>
                </div>
                <div>
                  <div className="or-wrapper">
                    <div className="fatglobe-wrapper">
                      <img src={slide3.earthIcon} />
                    </div>
                    <span className="medium">{slide3.milesOrLabel}</span>
                  </div>
                </div>
                <div className="factoid narrow">
                  <span className="large">
                    {slide3.miles1PlusGlobeCount} {slide3.miles1PlusBottom}
                  </span>
                </div>
              </>
            )}
          </div>
          <div id="SLIDE_4_PRINT" className={`slide slide_4`}>
            <img src={slide3.earthIcon} />
            <span className="large wrap pbup">
              {slide4.cloudTopLine1} {slide4.cloudTopLine2}
            </span>
            <div className="cloud-wrapper-wrapper">
              <div className="cloud-wrapper">
                <img src={slide4.cloudIcon} />
              </div>
              <span className="xlarge">{slide4.cloudDays}</span>
            </div>
            <span className="medium pbup">{slide4.cloudUnit}</span>
            <span className="medium">{slide4.cloudBottom}</span>
          </div>
          <div id="SLIDE_5_PRINT" className={`slide slide_5 `}>
            <img className="takeoff" src={slide5.landIcon} />
            <span className="medium setWidth wrap">{slide5.tripTop}</span>
            {slide5.cities.map((c) => (
              <div key={c} className="underline">
                <span className="large">{c}</span>
              </div>
            ))}
          </div>
          <div id="SLIDE_6_PRINT" className={`slide slide_6`}>
            <span className="medium">{slide6.bankedMilesTopLine1}</span>
            <span className="medium">{slide6.bankedMilesTopLine2}</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={slide6.asiaMilesIcon} />
              <span className="xlarge">{slide6.asiaMilesEarned}</span>
            </div>
            <span className="medium">{slide6.bankedMilesBottom}</span>
          </div>
          <div id="SLIDE_7_PRINT" className={`slide slide_7`}>
            <span>{slide7.favWaysTop}</span>
            {slide7.ways.map((w) => (
              <div key={w} className="underline">
                <span className="large">{w}</span>
              </div>
            ))}
            <span>{slide7.favWaysBottom}</span>
          </div>
          <div id="SLIDE_8_PRINT" className={`slide slide_8`}>
            <div className="plane-wrapper">
              <img src={slide8.airplaneIcon} />
            </div>
            <span className="medium setWidth wrap">{slide8.endLabel}</span>
            <div className="factoid">
              <span className="xlarge">{slide8.endYear}</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
      <div className="modalContent">
        <div className="modalHeader">
        <button className="closeModal" onClick={closeModal}>X</button>
          <div>Select To Share</div>
        </div>
        
        <div className="modalBody">
          {generatedSlideImages.map((item, index) => (
            // <li key={index}>{item}</li>
            <div className="slideImageItem" key={index}>
              <label>
              <input
                type="checkbox"
                className="itemCheckbox"
                checked={selectedSlideImages.includes(index)}
                onChange={(event) => handleCheckboxChange(index, event)}
              />
               <img className="slideImage" src={URL.createObjectURL(item)} />
            </label>
           
            </div>
          ))}
        </div>

        <div className="modalFooter">
          <button type="button" className="shareYourJourney" onClick={shareYourJourneyButton}>Share your journey </button>
        </div>
        {/* <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form> */}
        </div>
      </Modal>
    </>
  );
}

export default YearInReviewAnimation;
